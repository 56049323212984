<template>
  <div class="mobile-action-container">
    <div :class="{action:true}">
      <!-- <div class="left"> -->
      <div @click="league" class="league">
        <img src="/static/img/mobile/league.png?v=1">
        加盟
      </div>
      <div @click="callPhone" class="phone">
        <img src="/static/img/mobile/phone.png?v=1">
        电话
      </div>
      <!-- </div> -->
      <div class="app" @click="skipUrl(info.app_url)">下载APP</div>
    </div>
    <!-- <League :show.sync="showLeague" /> -->
  </div>
</template>

<script>
// import League from "@/components/mobile/league.vue";

export default {
  components: {
    // League,
  },
  data() {
    return {
      showLeague: false,
      info: {},
    };
  },
  computed: {
    isIphoneX() {
      return (
        /iphone/gi.test(navigator.userAgent) &&
        screen.height == 812 &&
        screen.width == 375
      );
    },
  },
  created() {
    this.$api("official/page/mobile_foot").then(({ data }) => {
      this.info = data;
    });
  },
  methods: {
    skipUrl(url) {
      location.href = url;
    },
    league() {
      this.$router.push({ path: "/mobile/join_now" });
    },
    callPhone() {
      location.href = "tel:" + this.info.phone_number;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-action-container {
  .action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px solid #e4e4e4;
    z-index: 19;

    padding: 38px 65px 38px 65px;

    //兼容IOS
    padding-bottom: calc(70px + constant(safe-area-inset-bottom));
    padding-bottom: calc(70px + env(safe-area-inset-bottom));

    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // justify-content: space-between;
    // align-items: center;

    .league,
    .phone {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 96px;
        height: 96px;
        margin-right: 19px;
      }

      font-size: 58px;
      font-weight: 400;
      color: #565252;
      line-height: 81px;
    }

    .app {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 499px;
      height: 134px;
      background: linear-gradient(159deg, #ffa83a 0%, #ff6f1b 100%);
      // background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
      border-radius: 67px;
      border: 1px solid rgba($color: #fff, $alpha: 0);

      font-size: 58px;
      font-weight: 500;
      color: #ffffff;
      // line-height: 80px;

      transition: 0.25s;
      &:active {
        border: 1px solid #ff6f1b;
        background: white;
        color: #ff6f1b;
      }
    }
  }
}
</style>